import React from "react";
import { Helmet } from "react-helmet";

function ProveSource() {
    return (
        <Helmet
            script={[{ 
                type: 'text/javascript', 
                innerHTML: '!function(o,i){window.provesrc&&window.console&&console.error&&console.error("ProveSource is included twice in this page."),provesrc=window.provesrc={dq:[],display:function(o,i){this.dq.push({n:o,g:i})}},o._provesrcAsyncInit=function(){provesrc.init({apiKey:"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhY2NvdW50SWQiOiI1YzUxYzUxNGJiY2FlMDdjYmFhMjI4ZDMiLCJpYXQiOjE1NDg4NjI3NDB9.FGaCuex5wDtXAlFCjVVIBl52b25mop-0acWE0cednDM",v:"0.0.3"})};var r=i.createElement("script");r.type="text/javascript",r.async=!0,r.charset="UTF-8",r.src="https://cdn.provesrc.com/provesrc.js";var e=i.getElementsByTagName("script")[0];e.parentNode.insertBefore(r,e)}(window,document);' 
            }]} 
        />
    );
}
export default ProveSource;
