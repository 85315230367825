import { graphql, useStaticQuery } from "gatsby";
import React, { useContext } from "react";
import PropTypes from "prop-types";

import Link from "../components/link";
import Logo from "../images/logo-dark.svg";

import {
    UilFacebook,
    UilInstagram,
    UilYoutube,
    UilTwitter,
} from "@iconscout/react-unicons";

function Icon({ icon }) {
    let uil = {
        facebook: UilFacebook,
        instagram: UilInstagram,
        youtube: UilYoutube,
        twitter: UilTwitter,
    };

    const MyIcon = uil[icon];
    return <MyIcon size="24" color="#718096" />;
}

import { Helmet } from "react-helmet";
import { ThemeContext } from "../context/themeContext";

import Toggle from "react-toggle";
import "react-toggle/style.css";

function Footer() {
    const { theme, setTheme } = useContext(ThemeContext);

    const handleThemeToggle = () => {
        if (theme === "light") {
            setTheme("dark");
        } else {
            setTheme("light");
        }
    };

    const data = useStaticQuery(
        graphql`
            {
                contentfulMenuBottom {
                    title
                    menu {
                        title
                        content {
                            route
                            title
                        }
                    }
                    social {
                        title
                        content {
                            icon
                            link
                        }
                    }
                }
            }
        `
    );

    return (
        <footer className="bg-gray-50 dark:bg-gray-800 py-8 md:py-20">
            <Helmet htmlAttributes={{ class: theme }}></Helmet>

            <nav className="flex flex-wrap md:flex-nowrap flex-col sm:flex-row justify-between max-w-6xl p-4 mx-auto text-sm md:space-x-10 border-b border-gray-200 dark:border-gray-600">
                <div className="w-full sm:w-1/2 md:w-1/5 flex flex-col mb-5">
                    <img src={Logo} className="w-28 mb-3" />
                    <p className="text-md text-gray-500 dark:text-gray-400">
                        {data.contentfulMenuBottom.title}
                    </p>
                </div>

                {data.contentfulMenuBottom.menu.map((menu) => (
                    <div
                        key={menu.title}
                        className="w-full sm:w-1/2 md:w-1/5 flex flex-col mb-5"
                    >
                        <h4 className="text-gray-900 dark:text-gray-200 font-medium text-lg mb-4 font-sans">
                            {menu.title}
                        </h4>
                        {menu.content.map((item) => (
                            <Link
                                className="menu_bottom mb-2 text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 transition-colors duration-100 ease-linear text-base"
                                key={item.title}
                                to={item.route}
                            >
                                {item.title}
                            </Link>
                        ))}
                    </div>
                ))}

                <div className="w-full sm:w-1/2 md:w-1/5 flex flex-col mb-5">
                    <h4 className="text-gray-900 dark:text-gray-200 font-medium text-lg mb-4 font-sans">
                        {data.contentfulMenuBottom.social.title}
                    </h4>

                    <div className="flex">
                        {data.contentfulMenuBottom.social.content.map(
                            (item) => (
                                <Link
                                    key={item.icon}
                                    href={item.link}
                                    className="menu_bottom mr-2"
                                >
                                    <Icon icon={item.icon} />
                                </Link>
                            )
                        )}
                    </div>

                    <h4 className="text-gray-900 dark:text-gray-200 font-medium text-lg mb-4 font-sans mt-8">
                        Layanan Pengaduan Konsumen
                    </h4>

                    <div className="flex flex-col">
                        <span className="text-md text-gray-500 dark:text-gray-400">PT Ayo Techno Idea</span>
                        <Link href="mailto:cs@smartseller.co.id" className="text-md text-gray-500 dark:text-gray-400 mb-4 hover:text-gray-800 dark:hover:text-gray-100">
                            cs@smartseller.co.id
                        </Link>
                        <span className="text-md text-gray-500 dark:text-gray-400">Direktorat Jenderal Perlindungan</span>
                        <span className="text-md text-gray-500 dark:text-gray-400">Konsumen dan Tertib Niaga</span>
                        <span className="text-md text-gray-500 dark:text-gray-400">Kementerian Perdagangan RI</span>
                        <span className="text-md text-gray-500 dark:text-gray-400">0853-1111-1010</span>
                    </div>
                </div>
            </nav>
            <div className="flex items-center max-w-6xl mx-auto py-6">
                <div className="flex-grow text-center text-gray-600 dark:text-gray-400 text-sm">
                    &copy; 2016 - {new Date().getFullYear()}, PT Ayo Techno Idea
                </div>
                <div className="w-auto flex items-center">
                    <Toggle
                        id="theme-toggle"
                        checked={theme === "dark" ? true : false}
                        onChange={handleThemeToggle}
                        icons={{
                            checked: (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4 bottom-2"
                                    viewBox="0 0 20 20"
                                    fill="#fff"
                                >
                                    <path d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z" />
                                </svg>
                            ),
                            unchecked: (
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="h-4 w-4"
                                    viewBox="0 0 20 20"
                                    fill="#fff"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            ),
                        }}
                    />
                </div>
            </div>
        </footer>
    );
}

Footer.defaultProps = {};

Footer.propTypes = {};

Icon.propTypes = {
    icon: PropTypes.string,
};

export default Footer;
