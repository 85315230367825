import React from "react";
import PropTypes from "prop-types";

import Header from "./header";
import Footer from "./footer";
import NotificationBar from "./notification-bar";

import ProveSrc from "../components/provesrc";
import Cookies from "universal-cookie";

const queryString = require("query-string");

function Layout({ children }) {
    // Save referral code to Cookies
    if (typeof window !== "undefined") {
        var parsed = "";
        parsed = queryString.parse(location.search);
        const cookies = new Cookies();
        if (typeof parsed.ref !== "undefined") {
            cookies.set("_ngorder_referral", parsed.ref, {
                path: "/",
                domain: ".ngorder.id",
                maxAge: 259200,
            });
            cookies.set("_ngorder_referral", parsed.ref, {
                path: "/",
                domain: ".smartseller.co.id",
                maxAge: 259200,
            });
            cookies.set("_ngorder_referral", parsed.ref, {
                path: "/",
                maxAge: 259200,
            });
        }

        if (Object.keys(parsed).length > 0) {
            let cookiesSetName = "_ngorder_utms";
            let cookiesSetOption = {
                path: "/",
                domain: ".ngorder.id",
                maxAge: 2628000,
            };
            let cookiesSmartSeller = {
                path: "/",
                domain: ".smartseller.co.id",
                maxAge: 2628000,
            };

            let utms = {};
            let utmsOld = cookies.get(cookiesSetName, cookiesSetOption);
            let utmsOldSmartSeller = cookies.get(
                cookiesSetName,
                cookiesSmartSeller
            );

            Object.keys(parsed).forEach(function (key) {
                let val = parsed[key];
                if (key.indexOf("utm") >= 0) {
                    utms[key] = val;
                }
            });

            if (
                utmsOld == undefined ||
                utmsOld.utm_source == undefined ||
                utmsOld.utm_source == "website" ||
                utmsOldSmartSeller == undefined ||
                utmsOldSmartSeller.utm_source == undefined ||
                utmsOldSmartSeller.utm_source == "website"
            ) {
                cookies.set(
                    cookiesSetName,
                    JSON.stringify(utms),
                    cookiesSetOption
                );
                cookies.set(
                    cookiesSetName,
                    JSON.stringify(utms),
                    cookiesSmartSeller
                );
            }
        }
    }

    return (
        <div className="flex flex-col min-h-screen font-body text-gray-800 dark:bg-gray-800 dark:text-gray-200">
            <NotificationBar />

            <Header />

            <main className="flex-1 w-full">{children}</main>

            <Footer />

            <ProveSrc />
        </div>
    );
}

Layout.defaultProps = {};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
