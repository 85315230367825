import { graphql, useStaticQuery } from "gatsby";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";

import { documentToHtmlString } from "@contentful/rich-text-html-renderer";
import parse from "html-react-parser";

import Button from "../components/button";

function NotificationBar() {
    const [notifBar, setNotifBar] = useState([]);
    const ENTRY_ID = "3TCKsodxdeKKM6q87qaNeV";

    const data = useStaticQuery(graphql`
        {
            contentfulMenuTop {
                cta {
                    title
                    route
                    type
                }
            }
        }
    `);

    useEffect(() => {
        fetch(
            `https://cdn.contentful.com/spaces/${process.env.GATSBY_CONTENTFUL_SPACE_ID}/environments/master/entries/${ENTRY_ID}?access_token=${process.env.GATSBY_CONTENTFUL_TOKEN}`
        )
            .then((response) => response.json())
            .then((resultData) => {
                let data = {
                    label: resultData.fields.label,
                    content: documentToHtmlString(resultData.fields.content),
                    publish: resultData.fields.publish,
                };
                setNotifBar(data);
            });
    }, []);

    return (
        <section id="notification-bar" className="bg-gray-50 dark:bg-gray-700">
            {notifBar.publish === true && (
                <div className="max-w-6xl mx-auto px-4 py-4 items-center">
                    <div className="flex-grow">
                            <div className="flex items-center">
                                <span className="flex-none bg-gray-600 dark:bg-green-500 text-white font-sans px-2 py-1 rounded-md text-xs mr-2 leading-tight">
                                    {notifBar.label}
                                </span>
                                <span className="flex-grow text-sm font-sans leading-none">
                                    {parse(notifBar.content)}
                                </span>
                            </div>
                    </div>
                    <div className="flex-none hidden">
                        {data.contentfulMenuTop.cta.map((btn) => (
                            <Button
                                key={btn.title}
                                text={btn.title}
                                url={btn.route}
                                color={btn.type}
                                size="md"
                                className="cta_notification-bar md:ml-4 md:mr-0 font-sans"
                            />
                        ))}
                    </div>
                </div>
            )}
        </section>
    );
}

NotificationBar.defaultProps = {};

NotificationBar.propTypes = {
    props: PropTypes.array,
};

export default NotificationBar;
