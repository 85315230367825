import PropTypes from "prop-types";
import React from "react";

function Button({ url, text, size, color, className }) {
  let sizeClass = ``;
  switch (size) {
    case `sm`:
      sizeClass = `text-xs px-2 py-1`;
      break;
    case `md`:
      sizeClass = `text-sm px-4 py-2`;
      break;
    case `lg`:
      sizeClass = `text-md px-6 py-3`;
      break;
    case `xl`:
      sizeClass = `text-lg px-6 py-3`;
      break;
  }

  let colorClass = ``;

  switch (color) {
    case `dark`:
      colorClass = `text-white bg-gray-700 hover:bg-gray-600 dark:bg-gray-800`;
      break;

    case `light`:
      colorClass = `text-gray-700 hover:text-gray-100 bg-gray-100 dark:bg-gray-200 hover:bg-gray-800 dark:hover:bg-gray-600`;
      break;

    case `soft-light`:
      colorClass = `text-primary hover:text-gray-100 bg-primary-opacity dark:bg-gray-200 hover:bg-primary dark:hover:bg-gray-600`;
      break;

    case `primary`:
      colorClass = `text-blue-100 hover:text-blue-100 bg-primary hover:bg-blue-800`;
      break;
    case `outline`:
      colorClass = `text-primary hover:text-blue-100 border border-primary hover:bg-blue-800`;
      break;
  }

  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className={`${sizeClass} ${colorClass} ${className} text-center w-auto font-medium tracking-wide rounded-md mr-2 transition-all ease-in duration-75 break-words`}
    >
      {text}
    </a>
  );
}

Button.defaultProps = {
  url: ``,
  text: ``,
  size: ``,
  className: ``,
};

Button.propTypes = {
  url: PropTypes.string,
  text: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.string,
  className: PropTypes.string,
};

export default Button;
